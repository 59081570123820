import React from 'react';

const Overlay = (props) => {
  let classes = 'overlay';
  if (props.className) classes = `overlay ${props.className}`;

  return (
    <div className={classes} onClick={props.onClick}></div>
  );
};

export default Overlay;
