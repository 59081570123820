import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from '../layout/Container';
import Nav from '../navigation/Nav';
import MobileNav from '../navigation/MobileNav';

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const navBurgerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <header className="header">
      <Container className="container-flex">
        <div className="header-logo">
          <Link to="/">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40px" height="10px" viewBox="0 0 40 10">
                <polygon fill="#9bd346" points="0,1 6.929,4.999 0,9 " />
                <ellipse fill="#FFFFFF" cx="13.918" cy="5" rx="3.957" ry="4" className="header-logo__circle" />
                <ellipse fill="#FFFFFF" cx="24.98" cy="5.001" rx="3.957" ry="3.999" className="header-logo__circle" />
                <ellipse fill="#FFFFFF" cx="36.043" cy="5.001" rx="3.957" ry="3.999" className="header-logo__circle" />
              </svg>
            </div>
            <p className="header-logo__name">
              <span>brian</span>coppoladesign.com
            </p>
          </Link>
        </div>
        <Nav />
        <MobileNav mobileNavOpen={mobileNavOpen} onClick={navBurgerToggle} />
      </Container>
    </header>
  );
};

export default Header;
