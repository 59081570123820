import React from 'react';

const PageHeadline = (props) => {
  return (
    <section className="page-headline">
      <h1>{props.h1}</h1>
      <h2>{props.h2}</h2>
    </section>
  );
};

export default PageHeadline;
