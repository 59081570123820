import React from 'react';

const MobileNavBurger = (props) => {
  return (
    <button
      className="nav-burger"
      id="nav-burger"
      tabIndex="0"
      aria-label="Menu"
      title="Toggle menu"
      aria-controls="navigation"
      onClick={props.onClick}
      aria-expanded={props.mobileNavOpen}
    >
      <div className="nav-burger--line"></div>
    </button>
  );
};

export default MobileNavBurger;
