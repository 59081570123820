import React from 'react';
// import { Link } from 'react-router-dom';
import PortfolioCards from '../components/layout/PortfolioCards';

const Home = () => {
  return (
    <>
      <section className="home-top">
        <div className="home-headline">
          <h1>Brian is a</h1>
          <h2>Front End Web Developer.</h2>
          <p>Game maker. Graphic Designer. All&#8209;around&nbsp;nerd. And&nbsp;more.</p>
        </div>
        <div className="home-intro">
          <p>
            I am a Front End Web Developer that enjoys getting his hands dirty taking design concepts and making them
            come alive as fully-functioning web applications, in a way that is visually compelling, accessible, and
            user&#8209;friendly. I have design experience, and I'm not afraid to utilize Photoshop or Illustrator to
            create or enhance visual elements.
          </p>
          <p>
            I'm also a serious tabletop/video game fanatic, with a certificate in game design at the University of
            Washington and experience with game testing, prototyping, and demoing. And a touch of experience with Unity.
          </p>
          <p>Finally, I'm a travel addict, I love sci&#8209;fi and fantasy stuff, and I play bass guitar.</p>
          {/* <p><Link to="/portfolio">View portfolio</Link></p> */}
        </div>
      </section>

      <section className="home-bottom" id="portfolio-cards-section">
        <PortfolioCards count="6" headline="Recent Projects" />
      </section>
    </>
  );
};

export default Home;
