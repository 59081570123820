const Button = (props) => {
  const { type, className, onClick } = props;
  const classes = `button ${className}`;

  return (
    <button type={type || 'button'} className={classes} onClick={onClick}>
      {props.children}
    </button>
  );
};

export default Button;
