import React from 'react';
import Card from '../ui/Card';
import { PORTFOLIO_DATA } from '../../app/data/portfolio_data';

const PortfolioCards = (props) => {
  const headline = props.headline;
  const count = props.count;
  let portfolioData = PORTFOLIO_DATA;

  if (count) {
    portfolioData = PORTFOLIO_DATA.filter((d) => d.id < count);
  }

  return (
    <>
      {headline && (
        <div className="portfolio-cards-headline">
          <h3>{headline}</h3>
        </div>
      )}
      <div className="portfolio-cards">
        {portfolioData.map((data) => {
          return <Card key={data.id} data={data} />;
        })}
      </div>
    </>
  );
};

export default PortfolioCards;
