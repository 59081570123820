import React from 'react';
import { Routes, Route, Navigate } from 'react-router';
import Header from './components/sections/Header';
import Main from './components/sections/Main';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import PortfolioItem from './pages/PortfolioItem';
import Footer from './components/sections/Footer';
import './App.css';

function App() {
  return (
    <>
      <Header />
      <Main>
        <Routes>
          <Route index element={<Home />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="portfolio/:itemUrlName" element={<PortfolioItem />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Main>
      <Footer />
    </>
  );
}

export default App;
