import React from 'react';
import { NavLink } from 'react-router-dom';
import Container from '../layout/Container';
import myResume from '../../app/docs/BrianCoppola_2024.pdf';

const Footer = (props) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <div className="footer__blurb">
          <p>
            This responsive website was hand-coded by Brian Coppola using React, JavaScript, HTML, CSS, Node.js,
            pixie&nbsp;dust, The&nbsp;Force, a handful of dilithium crystals, and a couple of&nbsp;secret&nbsp;spells.
          </p>
        </div>
        <div className="footer__bottom">
          <div className="footer__links">
            <NavLink to="/portfolio">Portfolio</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </div>
          <div className="footer__icons">
            <a href={myResume} target="_blank" rel="noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                viewBox="-3.5 0 19 19"
                className="footer__icons--resume"
              >
                <path d="M11.16 16.153a.477.477 0 0 1-.476.475H1.316a.477.477 0 0 1-.475-.475V3.046a.477.477 0 0 1 .475-.475h6.95l2.893 2.893zm-1.11-9.924H8.059a.575.575 0 0 1-.574-.574V3.679H1.95v11.84h8.102zM3.907 4.92a1.03 1.03 0 1 0 1.029 1.03 1.03 1.03 0 0 0-1.03-1.03zm4.958 3.253h-5.87v1.108h5.87zm0 2.354h-5.87v1.109h5.87zm0 2.354h-5.87v1.109h5.87z" />
              </svg>
            </a>
            <a href="mailto:brian@briancoppoladesign.com" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/in/briancoppola1/" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </div>
          <div className="footer__copyright">
            <p>&copy; {currentYear} Brian Coppola Design</p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
