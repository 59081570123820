export const HEADLINES = [
  {
    id: 0,
    headline: 'Portfolio',
    subhead: "Sampling of Brian's work",
  },
  {
    id: 1,
    headline: 'Contact',
    subhead: 'Say hello to Brian',
  },
];
