import React from 'react';
import Container from '../layout/Container';

const Main = (props) => {
  return (
    <main className="main">
      <Container>{props.children}</Container>
    </main>
  );
};

export default Main;
