import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PageHeadline from '../components/sections/PageHeadline';
import { PORTFOLIO_DATA } from '../app/data/portfolio_data';

const Portfolio = () => {
  const { itemUrlName } = useParams();
  const { name, title, image, link, linkText, description } = PORTFOLIO_DATA.find(
    (item) => item.urlName === itemUrlName
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const newPageSVG = {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
      </svg>
    ),
  };

  return (
    <>
      <PageHeadline h1="Portfolio" h2={title} />

      <section className="portfolio">
        <div className="portfolio-left">
          {link && (
            <Link to={link} target="_blank">
              <img src={image} alt={name} />
            </Link>
          )}
          {!link && <img src={image} alt={name} />}
        </div>

        <div className="portfolio-right">
          <div className="portfolio-description">
            {link && (
              <Link to={link} target="_blank">
                {linkText}
                {newPageSVG.icon}
              </Link>
            )}

            {description.map((item, index) => {
              if (!item.list) {
                return <p key={index}>{item.text}</p>;
              }

              return (
                <ul key={index}>
                  {item.text.map((listItem, listIndex) => {
                    return <li key={listIndex}>{listItem}</li>;
                  })}
                </ul>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
