import React from 'react';
import PageHeadline from '../components/sections/PageHeadline';
import PortfolioCards from '../components/layout/PortfolioCards';
import { HEADLINES } from '../app/data/headline_data';

const Portfolio = () => {
  const { headline, subhead } = HEADLINES.find((headline) => headline.headline === 'Portfolio');

  return (
    <>
      <PageHeadline h1={headline} h2={subhead} />

      <section className="">
        <PortfolioCards />
      </section>
    </>
  );
};

export default Portfolio;
