import React, { useState } from 'react';
import PageHeadline from '../components/sections/PageHeadline';
import { validateContactForm } from '../utilities/validateContactForm';
import Button from '../components/ui/Button';
import { HEADLINES } from '../app/data/headline_data';

const Contact = () => {
  const { headline, subhead } = HEADLINES.find((headline) => headline.headline === 'Contact');
  const defaultValues = {
    name: '',
    email: '',
    message: '',
  };
  const defaultVisited = {
    name: false,
    email: false,
    message: false,
  };

  const [values, setValues] = useState(defaultValues);
  const [visited, setVisited] = useState(defaultVisited);
  const [status, setStatus] = useState('Submit');

  const nameError = validateContactForm(values).firstName;
  const emailError = validateContactForm(values).email;
  const messageError = validateContactForm(values).message;

  const formIsValid = !nameError && !emailError && !messageError;

  const setValuesHandler = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [e.target.id]: e.target.value,
    }));
  };

  const setVisitedHandler = (e) => {
    setVisited((prevVisited) => ({
      ...prevVisited,
      [e.target.id]: true,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setVisited({
      name: true,
      email: true,
      message: true,
    });

    if (!formIsValid) {
      return;
    }

    setStatus('Sending...');

    let details = {
      name: values.name,
      email: values.email,
      message: values.message,
    };
    let response = await fetch('https://us-central1-bcd-website-313118.cloudfunctions.net/bcdwebsiteServer/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(details),
    });

    setStatus('Submit');
    let result = await response.json();
    alert(result.status);
  };

  return (
    <>
      <PageHeadline h1={headline} h2={subhead} />

      <section className="contact">
        <div className="contact-intro">
          <p>
            Feel free to send me a general message or one regarding a new project you think I'd be great for and I'll
            get back to you as soon as possible.
          </p>
        </div>
        <form className="contact-form">
          <p>All fields required</p>
          <div className="form-group">
            <label htmlFor="name">
              {!visited.name && <p>Name</p>}
              {visited.name && !nameError && <p>Name</p>}
              {visited.name && nameError && <p className="form-error-message">{nameError}</p>}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={visited.name && nameError ? 'form-error' : undefined}
              value={values.name}
              onChange={(e) => setValuesHandler(e)}
              onBlur={(e) => setVisitedHandler(e)}
              placeholder="Name"
              required=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {!visited.email && <p>Email address</p>}
              {visited.email && !emailError && <p>Email address</p>}
              {visited.email && emailError && <p className="form-error-message">{emailError}</p>}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={visited.email && emailError ? 'form-error' : undefined}
              value={values.email}
              onChange={(e) => setValuesHandler(e)}
              onBlur={(e) => setVisitedHandler(e)}
              placeholder="Email address"
              required=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">
              {!visited.message && <p>Your message</p>}
              {visited.message && !messageError && <p>Your message</p>}
              {visited.message && messageError && <p className="form-error-message">{messageError}</p>}
            </label>
            <textarea
              id="message"
              name="message"
              rows="7"
              className={visited.message && messageError ? 'form-error' : undefined}
              value={values.message}
              onChange={(e) => setValuesHandler(e)}
              onBlur={(e) => setVisitedHandler(e)}
              placeholder="Your message"
              required=""
            ></textarea>
          </div>
          <Button type="submit" className="contact-button" onClick={submitHandler}>
            Send
          </Button>
        </form>
      </section>
    </>
  );
};

export default Contact;
