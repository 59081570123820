export const validateContactForm = (values) => {
  const errors = {};
  const { name, email, message } = values;

  if (!name) {
    errors.firstName = 'Name required';
  } else if (name.length < 2) {
    errors.firstName = 'Must be at least 2 characters.';
  } else if (name.length > 30) {
    errors.firstName = 'Must be 30 characters or less.';
  }

  if (!email) {
    errors.email = 'Email required';
  } else if (!email.includes('@')) {
    errors.email = 'Email must contain a @.';
  }

  if (!message) {
    errors.message = 'Message required';
  }

  return errors;
};
