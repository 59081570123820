import React from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {
  const { name, urlName, title, stack, thumb } = props.data;
  const destinationPath = `/portfolio/${urlName}`;

  return (
    <Link className="card" to={destinationPath}>
      <img src={thumb} alt={name} />
      <div className="card-body">
        <h4>{title}</h4>
        <p>{stack}</p>
      </div>
    </Link>
  );
};

export default Card;
